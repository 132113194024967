@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #080912 !important;
    color: white !important;
    overflow-x: hidden;

    scroll-behavior: smooth;
}

html {
    scroll-behavior: smooth;
}

code {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
}

h1,
h2,
h3,
h4,
p {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
    margin: 0;
}

a {
    text-decoration: none !important;
    color: unset !important;
}