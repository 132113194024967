#legal {
    .legalSpace {
        padding-top: 120px;
        padding-bottom: 50px;
        width: 86%;
        margin: 0 auto;
    }

    p {
        color: rgba($color: white, $alpha: .8) !important;
        font-size: 16px !important;
    }

    li {
        font-size: 16px !important;
        font-family: 'Satoshi', sans-serif !important;
        font-weight: 400;
        color: rgba($color: white, $alpha: .8) !important;
    }

    h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 8px;
        margin-top: 20px;
    }

    .legalHeader {
        color: white !important;
        font-weight: 500 !important;
        font-size: 38px !important;
        margin-bottom: 8px !important;
    }

    @media (max-width: 768px) {
        .legalSpace {
            padding-top: 100px;
            padding-bottom: 50px;
            width: 90%;
            margin: 0 auto;
        }
    }
}