#stats {
  width: 90%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  align-items: center;

  .statGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 50px;

    // max-width: 600px;
    margin: 0 auto;
    text-align: center;
    height: 100%;


    h2 {
      color: white;
      font-weight: 700 !important;
      font-size: 30px;
      margin: 0;
    }


    .statAnimate {
      background-image: linear-gradient(to right, #B224F5 0%, #31EDF9);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-background-clip: text;
      -moz-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;

      background-size: 300%;
      background-position: -100%;

      animation: animatedText 3s infinite alternate-reverse;
    }

    p {
      margin: 4px 0 0 0;
    }

    @keyframes animatedText {

      from {
        background-position: 0%;
      }

      to {
        background-position: 100%;
      }

    }

    // div:nth-child(2) {
    //     border-right: 1px solid #544C6B;
    //     border-left: 1px solid #544C6B;
    //     padding: 0 15px;
    // }
  }

  @media (max-width: 768px) {
    height: unset;
    margin-top: unset;

    h2 {
      font-weight: 700 !important;
      font-size: 22px !important;
      margin: 0;
    }


    p {
      font-size: 12px;
      margin: 0;
      min-width: 80px;
      width: 100%;
    }

    .statGrid {
      margin: 0 auto !important;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: space-between !important;
      width: 100% !important;
      column-gap: unset !important;

      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
}