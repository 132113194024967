#navbar {
    width: 100%;
    //stick to top
    // -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    // -moz-animation: fadein 2s; /* Firefox < 16 */
    // -ms-animation: fadein 2s; /* Internet Explorer */
    // -o-animation: fadein 2s; /* Opera < 12.1 */
    // animation: fadein 2s;
    // animation-fill-mode: forwards;
    .container-fluid {
        padding: 0 !important;
    }
    .navlogo {
        width: 120px;
        margin: 0;
        padding: 0;
        height: auto;
    }
    .bg-custom {
        // padding: 20px 0 20px 0;
        transition: 0.3s;
        -webkit-transition: 0.3s !important;
        border-bottom: 1px transparent;
    }
    .navbarWidth {
        width: 90%;
        margin: 0 auto;
        max-width: 1400px;
        justify-content: center;
    }
    .navbarScroll {
        padding: 10px 0;
        background-color: rgba(15, 12, 35, 0.3);
        backdrop-filter: blur(8px) opacity(1);
        -webkit-backdrop-filter: blur(8px) opacity(1);
        border-bottom: 0.5px solid #544c6b;
    }
    .navbar-toggler {
        color: unset !important;
        border: none !important;
    }
    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255,255,255, 0.9%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
        border-color: #FFFF;
    }
    .navbar-toggler:focus {
        box-shadow: unset;
    }
    .nav-link {
        color: #FFFF !important;
        font-size: 14px;
        font-weight: 500 !important;
        // border-radius: 15px;
        padding: 8px 12px;
        transition: all 0.2s ease 0s;
        -webkit-transition: all 0.2s ease 0s;
        text-align: center;
        opacity: .8;
    }
    .nav-link:hover {
        // transform: scale(1.05);
        opacity: 1;
    }
    .nav-item {
        margin: 0 6px;
    }
    .purchaseButton {
        display: inline-block;
        transition: 0.2s !important;
        -webkit-transition: 0.2s !important;
        z-index: 1;
        padding: 12px 35px !important;
        color: white;
        background: transparent;
        border-radius: 10px;
        border: 2px solid #8D8E93;
        font-weight: 700;
        font-size: 14px;
    }
    .purchaseButton:hover {
        background: white;
        border: white solid 2px;
        color: black;
    }
    .active {
        color: #8d68ff;
    }
    .socialLogo {
        width: auto;
        height: 15px;
    }
    .socialContainer {
        display: flex;
        gap: 20px;
        justify-content: flex-end;
        margin: 0;
        list-style: none;
    }
}

@media (max-width: 1000px) {
    #navbar {
        .navbarWidth {
            width: 90%;
            margin: 0 auto;
            max-width: 1400px;
            justify-content: space-between;
        }
        .bg-custom {
            padding: 20px 0;
            // background-color: rgba(15, 12, 35, .3);
            // backdrop-filter: blur(8px) opacity(1);
            // -webkit-backdrop-filter: blur(8px) opacity(1);
            // border-bottom: .5px solid #544C6B;
        }
        .navlogo {
            width: 100px;
            height: auto;
            margin-right: 20px;
        }
        .nav-link {
            padding-left: 0;
        }
        .nav-link:hover {
            background-color: transparent;
        }
        .current {
            padding-left: 18px;
        }
        .scrollspy {
            margin-top: 15px !important;
        }
    }
}


/* Key Frames */

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


/* Firefox < 16 */

@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


/* Safari, Chrome and Opera > 12.1 */

@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

// Other
.modal {
    background: #1C1B27BF !important;
    backdrop-filter: blur(7px);
    z-index: -1;
    border: none !important;
    overflow-y: hidden !important;
    .nav-link {
        font-weight: 500;
        font-size: 26px;
        text-align: center;
        // margin: 5px 0;
        color: #FFFFFF !important;
    }
    .socialContainer {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        margin: 0;
        list-style: none;
        padding: 0 !important;
        width: 60%;
    }
    .socialLogo {
        height: 20px;
    }
}

.modal-dialog {
    margin: 0 !important;
    max-width: none !important;
    width: 100% !important;
    height: 100vh;
}

.modal-content {
    height: 90vh;
    overflow-y: hidden !important;
    border-radius: unset !important;
    border: none !important;
    background-color: unset !important;
}

.modal-body {
    width: 60%;
    display: flex;
    margin: 0 auto;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.btn-close {
    width: 10px !important;
    height: 10px !important;
    color: #ffff !important;
    background-image: url("data:image/svg+xml,<svg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill='%23fff'><path d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/></svg>") !important;
    opacity: 1 !important;
}

.modal-header,
.modal-footer {
    border: none !important;
}