#emailoptin {
    background-image: url(../../Assets/landbg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    .content {
        display: flex;
        flex-direction: column;
    }
    .footer {
        width: 100%;
        height: 100px;
        background-color: #000000;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
            color: white;
            margin: 0;
        }
    }
    .emailForm {
        margin: 50px;
    }
    .top-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 20%;
        width: 100%;
        .logo {
            width: 50%;
            height: 50%;
        }
    }
    .submit-button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        p {
            text-align: center;
            margin: 0;
            color: white;
            font-size: 13px;
        }
    }
    .sub-text-outer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .emailFormInput {
        border-radius: 10px;
        border: 1px solid white;
        width: 100%;
        padding: 10px;
        color: white;
        background-color: rgba($color: white, $alpha: 0.00);
        align-items: center;
    }
    .custom-plus {
        justify-content: center;
        align-items: center;
        display: flex;
        width: 100%;
        height: 100%;
        font-size: 30px;
    }
    .custom-plus p {
        margin: 0;
        font-size: 30px;
        color: white
    }
    .emailOptInForm {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        // overflow: hidden
    }
    .navlogo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 10%;
        min-width: 200px;
    }
    .nav {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .list-item {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 10px;
    }
    .list-item p {
        text-align: center;
        margin: 0;
        padding: 5px;
    }
    .emailInput {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    .emailInputOuter {
        gap: 30px;
        width: 400px;
    }
    .continueButton {
        width: 40%
    }
    .checkicon {
        width: 20px;
        height: 20px;
        filter: invert(55%) sepia(87%) saturate(2517%) hue-rotate(180deg) brightness(101%) contrast(101%);
    }
    .top-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 20%;
        width: 100%;
        h1 {
            text-align: center;
            max-width: 1200px;
        }
    }
    .form-center {
        gap: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .list-item-container {
        margin: 10px;
        max-width: 1200px;
        p {
            margin: 0;
        }
    }
    .form-center {
        gap: 10px;
        display: flex;
        flex-direction: column;
    }
    .list-item p {
        text-align: left;
        margin: 0;
        padding: 5px;
    }
    .emailFormInput {
        width: 100%;
        max-width: 500px;
    }
    .splitter {
        width: 100%;
        height: 1px;
        text-align: center;
    }
    @media (max-width: 768px) {
        .top-header {
            h1 {
                margin: 10px;
                font-size: 20px;
            }
        }
        .emailFormInput {
            width: 70%;
        }
        .emailInputOuter {
            width: 100%;
        }
    }
}