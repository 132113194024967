#footer {
    background: #0B0C15;
    overflow-x: hidden;

    ul {
        display: inline-block;
        padding: 0;
    }

    li {
        list-style-type: none;
        text-decoration: none;
        padding-bottom: 10px;
    }

    a {
        font-weight: 400;
        font-size: 13px !important;
        text-decoration: none;
        opacity: .8;
        color: #FFFF !important;
        transition: .2s;
    }

    a:hover {
        opacity: 1;
    }

    p {
        margin: 0 !important;
        font-size: 14px;
    }

    .footerContainer {
        position: relative;
        padding: 60px 0 60px 0;
        width: 80%;
        margin: 0 auto;
        max-width: 1400px;
        z-index: 1;
    }

    .company {
        opacity: 0.5;
    }

    .footerLeftText {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .socialLogo {
        width: 20px;
        height: auto;
        margin-left: 10px;
    }

    .footerTop {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        gap: 30px;

    }

    .navlogo {
        width: 50px;
        height: auto;
    }

    .footerRedirects {
        display: flex;
        align-items: flex-start;
        justify-content: space-evenly;
        width: 100%;
        gap: 20px;
    }

    .listHeader {
        font-weight: 600;
        font-size: 15px;
        margin-bottom: 10px !important;
    }

    .listText {
        text-decoration: none;
        font-size: 15px;
        white-space: nowrap;
    }


    .dashboardButton {
        font-weight: 600 !important;
        font-size: 14px !important;
        display: inline-block;
        transition: 0.2s !important;
        -webkit-transition: 0.2s !important;
        z-index: 1;
        padding: 12px 28px !important;
        color: white;
        background: transparent;
        border-radius: 8px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 10px;
    }

    .dashboardButton:hover {
        background: white;
        color: black !important;
    }

    .footerBottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    .backToTopBtn {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
    }

    @media (max-width: 905px) {
        padding-top: 40px;

        .footerTop {
            flex-direction: column;
            gap: 15px;
            align-items: center;
        }

        .footerBottom {
            margin-top: 50px;
        }

        .footerLeftText {
            text-align: center;
        }

        .alignLogo {
            text-align: center;
        }

        .navlogo {
            width: 50px;
            height: auto;
            margin: 0 auto;
        }

    }

    @media (max-width: 768px) {

        .footerContainer {
            position: relative;
            padding: 10px 0 60px 0;
            width: 80%;
            margin: 0 auto;
            max-width: 1400px;
            z-index: 1;
        }

        .footerTop {
            display: grid;
            gap: 20px;
            grid-template:
                "btn"
                "links"
                "logo";
            margin: 0 auto;
            align-items: center;
            justify-content: center;
        }

        .footerLeft {
            grid-area: logo;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            justify-items: center;

        }

        .startResellingBtn {
            margin: 0 auto;
            grid-area: btn;
        }

        .footerRedirects {
            display: grid;
            align-items: flex-start;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            width: 100%;
            grid-area: links;
            gap: 30px 60px;
        }

        .dashboardButton {
            margin-bottom: 20px;
        }
    }

}