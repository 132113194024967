p {
    color: #aeb4c9;
    font-size: 16px;
}

#landing {
    height: 100vh;
    width: 100%;
    text-align: center;
    background-image: url(../../Assets/landbg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    font-family: 'Inter', sans-serif;
    align-items: center;
    display: flex;
    flex-direction: column;

    .landingContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 100vh;
        margin-bottom: 150px;
    }

    h1 {
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        margin: 0 auto;
        background-image: linear-gradient(75deg, #b224f5 -50%, #31edf9 150%);
        -webkit-background-clip: text;
        -webkit-text-stroke: 1px transparent;
    }

    h2 {
        font-size: 18px;
        font-weight: 700;
    }

    .landingText p {
        max-width: 800px;
        text-align: center;
    }

    .landingText {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .sub-text {
        font-size: 20px;
        font-weight: 400;
        color: #aeb4c9;
        text-align: left;
        align-items: left;
        width: 100%;
    }

    .checkicon {
        width: 20px;
        height: 20px;
        filter: invert(55%) sepia(87%) saturate(2517%) hue-rotate(180deg) brightness(101%) contrast(101%);
    }

    .landingText {
        margin-top: 70px;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    //make h1 in landingText wide 
    .landingText h1 {
        font-size: 35px;
        font-weight: 700;
        width: 100%;
    }

    .textGradient {
        font-weight: 700;
        background-image: linear-gradient(75deg, #b224f5 -50%, #31edf9 150%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
    }

    .landingTools {
        margin-top: 30px;
        position: relative;
        z-index: 2;

        h2 {
            margin-bottom: 25px;
        }
    }

    .landCards {
        display: flex;
        justify-content: center;
        // margin-top: 25px;
        gap: 15px;
        outline: rgba($color: white, $alpha: 0.06) 2px solid;
        width: fit-content;
        margin: 0 auto;
        padding: 15px;
        border-radius: 20px;
    }

    .cardContainer {
        max-width: 290px;
        background-color: rgba($color: white, $alpha: 0.06);
        border-radius: 20px;
        text-align: center;

        // padding: 20px;
        .cardHeader {
            background-color: rgba($color: white, $alpha: 0.04);
            padding: 18px;
            border-radius: 20px 20px 0 0;
        }

        h2 {
            font-size: 18px;
            margin: 0;
        }

        .cardInner {
            padding: 0 18px 18px 18px;
        }

        .cardImage {
            width: 100%;
            margin: 15px 0;
            // opacity: .8;
        }

        .joinButton {
            color: white;
            transition: 0.2s;
            // background: linear-gradient(93.99deg, #B224F5 0.76%, #31EDF9 104.49%);
            background-image: linear-gradient(to right, #b224f5 0%, #31edf9);
            background-size: 125% auto;
            padding: 15px 15px !important;
            width: 100%;
            border-radius: 10px;
            outline: none;
            border: none;
            font-size: 16px;
            font-weight: 700 !important;
        }

        .joinButton:hover {
            background-position: right center !important;
        }

        .learnButton {
            color: white;
            background: transparent;
            border: #8d8e93 solid 2px;
            padding: 15px 15px !important;
            width: 100%;
            border-radius: 10px;
            outline: none;
            font-size: 16px;
            font-weight: 700 !important;
            transition: 0.2s;
        }

        .learnButton:hover {
            background: white;
            border: white solid 2px;
            color: black !important;
        }
    }

    .giftIcon {
        width: 15px;
        margin-left: 4px;
        padding-bottom: 4px;
    }

    .purpBlob {
        position: absolute;
        width: 200px;
        height: 200px;
        margin-left: auto;
        margin-right: auto;
        left: 500px;
        right: 0;
        top: 300px;
        z-index: 1;
        background: #b224f5;
        opacity: 0.5;
        filter: blur(150px);
        animation: breathe 4s ease-in-out infinite alternate-reverse both;
        overflow: visible !important;
    }

    .blueBlob {
        position: absolute;
        width: 200px;
        height: 200px;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 500px;
        top: 300px;
        z-index: 1;
        background: #31edf9;
        opacity: 0.5;
        filter: blur(150px);
        animation: breathe 4s ease-in-out infinite alternate both;
        overflow: visible !important;
    }

    @media (max-width: 768px) {
        height: 110vh;
        gap: 0px;

        .continueButton {
            width: 40%;
        }

        .landingContent {
            margin-top: 0px;
            gap: 20px;
        }

        .emailInputOuter {
            align-items: center;
            width: 100%;
        }

        h1 {
            font-size: 20px;
        }

        h2 {
            font-size: 16px;
        }

        .landingText {
            margin-top: 40px;
        }

        .sub-text-outer {
            width: 100%;
            margin: 0 auto;
            padding: 0 20px;
        }

        .landCards {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 95% !important;
            // margin-top: 25px;
            gap: 10px;
            outline: none !important;
            border: rgba($color: white, $alpha: 0.06) 1px solid;
            width: fit-content;
            margin: 0 auto;
            padding: 5px !important;
            border-radius: 20px;
            -webkit-border-radius: 20px !important;
        }

        .landingText {
            padding: 0 0px;
            width: 90%;
        }

        .blueBlob {
            display: none;
        }

        .purpBlob {
            display: none;
        }

        .cardContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 0 auto;
            max-width: unset;
            justify-content: space-between;

            .cardHeader {
                padding: 15px 5px;
            }

            .cardInner {
                padding: 0 10px 10px 10px;
            }

            .joinButton {
                color: white;
                transition: 0.2s;
                // background: linear-gradient(93.99deg, #B224F5 0.76%, #31EDF9 104.49%);
                background-image: linear-gradient(to right, #b224f5 0%, #31edf9);
                background-size: 125% auto;
                padding: 15px 0 !important;
                width: 100%;
                border-radius: 10px;
                outline: none;
                border: none;
                font-size: 11px;
                font-weight: 600 !important;
            }

            .learnButton {
                color: white;
                background: transparent;
                border: #8d8e93 solid 1px;
                padding: 15px 0 !important;
                width: 100%;
                border-radius: 10px;
                outline: none;
                font-size: 11px;
                font-weight: 600 !important;
            }

            .headerText {
                font-weight: 700;
                font-size: 11px;
                text-align: center;
            }
        }
    }

    @keyframes breathe {
        from {
            background: #7336ff;
        }

        to {
            background-color: #4df8ff;
        }
    }
}

// About
.about {
    background-color: #13141c;
    // padding: 120px 0;
    overflow: hidden;
    width: 100%;
    position: relative;

    .frugalHeader {
        text-align: center;
    }

    .twoColNew {
        display: flex;
        // grid-template-columns: 1fr 1fr;
        align-items: center;
        // column-gap: 40px;
        // position: relative;
        max-width: 80%;
        margin: 0 auto;
        min-height: 600px;

        // padding: 40px 0 0 0;
        .text {
            position: relative;
            z-index: 3;
        }
    }

    .twoCol {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        column-gap: 40px;
        position: relative;
        max-width: 80%;
        margin: 0 auto;
        padding: 40px 0 0 0;

        .text {
            position: relative;
            z-index: 3;
        }
    }

    .aboutText {
        max-width: 650px;
        width: 100%;
        position: relative;
        z-index: 3;
    }

    .textMobile {
        display: none;
    }

    .textDesktop {
        display: block !important;
        position: relative;
        z-index: 3;
        padding-bottom: 30px;
    }

    .hideDesktop {
        display: none;
    }

    .textGradient {
        font-weight: 700;
        background-image: linear-gradient(75deg, #b224f5 -50%, #31edf9 150%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
    }

    .featuresSection {
        display: inline-flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 30px;
        position: relative;
        z-index: 3;
        gap: 25px;

        .features {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }

        .featuresNew {
            display: inline-flex;
            justify-content: start;
            text-align: left;
            flex-direction: column;
            gap: 10px;
        }

        .featureIconBox {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0) 100%);
            opacity: 0.8;
            border: 1px solid #ffffff;
            border-radius: 12px;
            min-width: 50px;
            min-height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .featureIcons {
            width: auto;
            height: 25px;
        }

        #consoleIcon {
            height: 18px !important;
        }

        .featuresText {
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 0 !important;
            font-weight: 700 !important;
            background-image: linear-gradient(75deg, #ba48ee -50%, #58edf7 125%);
            -webkit-background-clip: text;
            -webkit-text-stroke: 1px transparent;
        }
    }

    .featuresSectionNew {
        display: inline-flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 30px;
        position: relative;
        z-index: 3;

        .features {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }

        .featuresNew {
            display: inline-flex;
            justify-content: start;
            text-align: left;
            flex-direction: column;
            gap: 10px;
        }

        .featureIconBox {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.11) 0%, rgba(255, 255, 255, 0) 100%);
            opacity: 0.8;
            border: 1px solid #ffffff;
            border-radius: 12px;
            min-width: 50px;
            min-height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .featureIcons {
            width: auto;
            height: 25px;
        }

        #consoleIcon {
            height: 18px !important;
        }

        .featuresText {
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 0 !important;
            font-weight: 700 !important;
            background-image: linear-gradient(75deg, #ba48ee -50%, #58edf7 125%);
            -webkit-background-clip: text;
            -webkit-text-stroke: 1px transparent;
        }
    }

    .featuresBoxes {
        background-color: rgba($color: #1F2027, $alpha: .95);
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;

        p {
            margin: 0;
        }

        .featuresNew {
            padding: 25px;
            min-height: 100%;
        }

        .featuresNew:nth-child(2) {
            border-left: rgba($color: white, $alpha: 0.5) solid 1px;
            border-right: rgba($color: white, $alpha: 0.5) solid 1px;
        }
    }

    h2 {
        font-size: 38px;
        // margin-bottom: 10px;
        background-image: linear-gradient(75deg, #b224f5 -50%, #31edf9 150%);
        -webkit-background-clip: text;
        -webkit-text-stroke: 1px transparent;
    }

    .mb30 {
        margin-bottom: 30px;
    }

    .aboutImage {
        width: 480px;
        height: auto;
        z-index: 1;
        position: absolute;
        bottom: -50px;
        right: 70px;
    }

    .joinButton {
        color: white;
        background: linear-gradient(93.99deg, #b224f5 0.76%, #31edf9 104.49%);
        background-size: 125% auto;
        padding: 15px 15px !important;
        border-radius: 10px;
        width: 180px;
        margin-top: 20px;
        outline: none;
        border: none;
        font-size: 16px;
        font-weight: 700 !important;
        transition: 0.2s !important;
    }

    .joinButton:hover {
        background-position: right center !important;
    }

    .travelbtn {
        background: #1724f4 !important;
    }

    .travelbtn:hover {
        background: #ffffff !important;
        color: #1724f4 !important;
    }

    .videoContainer {
        position: relative;
        display: flex;
        // height: fit-content;
        // width: fit-content;
        margin-top: 35px;
        margin-bottom: 30px;

        video {
            width: 100%;
            border-radius: 20px;
            overflow: hidden;
            z-index: 1;
            min-width: 450px;
        }

        .alertsImg {
            position: absolute;
            width: auto;
            height: 120px;
            right: -20px;
            top: -50px;
            z-index: 2;
        }

        .freebieHeader {
            margin-bottom: 0 !important;
        }

        .purpBlob {
            position: absolute;
            width: 200px;
            height: 200px;
            left: unset;
            right: -20px;
            bottom: -50px !important;
            z-index: 1;
            background: #b224f5;
            opacity: 0.5;
            filter: blur(150px);
            animation: breathe 4s ease-in-out infinite alternate-reverse both;
            overflow: visible !important;
        }

        .blueBlob {
            position: absolute;
            width: 200px;
            height: 200px;
            left: -20px;
            right: unset;
            top: -50px;
            z-index: 1;
            background: #31edf9;
            opacity: 0.5;
            filter: blur(150px);
            animation: breathe 4s ease-in-out infinite alternate both;
            overflow: visible !important;
        }

        .backImg1 {
            position: absolute;
            top: -50px;
            left: -50px;
            width: 120px;
            height: auto;
            z-index: 0;
            opacity: 0.8;
            overflow-x: hidden;
        }

        .backImg2 {
            position: absolute;
            bottom: -100px;
            right: -100px;
            width: 170px;
            height: auto;
            z-index: 0;
            opacity: 0.8;
            overflow-x: hidden;
        }
    }

    .frenzyLearnBtn {
        background-image: linear-gradient(93.99deg, #6cd1fb 0.76%, #ff7ee3 104.49%) !important;
    }

    .frugalLogo {
        width: 150px;
        margin-top: 75px;
        margin-bottom: 10px;
        position: relative !important;
        z-index: 1;
    }

    // @media (max-width: 1000px) {
    //   .aboutImage {
    //     width: 400px;
    //     height: auto;
    //     z-index: 1;
    //     position: relative;
    //     bottom: -50px;
    //   }
    // }
    @media (max-width: 1000px) {
        padding: 60px 0;

        h2 {
            font-size: 30px;
            text-align: center;
            width: 100%;
        }

        p {
            font-size: 15px;
            position: relative;
            z-index: 3;
        }

        .textMobile {
            display: block !important;
        }

        .textDesktop {
            display: none !important;
        }

        .hideDesktop {
            display: block;
        }

        .mb30 {
            text-align: center !important;
        }

        .twoCol {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            column-gap: 0px;
            max-width: unset !important;
            width: 90%;
            margin: 0 auto;
            padding: 20px 0 0 0;
            z-index: 1;
        }

        .twoColNew {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            column-gap: 0px;
            max-width: unset !important;
            width: 90%;
            margin: 0 auto;
            padding: 0px 0 0 0;
            z-index: 1;
        }

        .aboutImage {
            width: 450px;
            height: auto;
            z-index: 1;
            position: absolute;
            bottom: -250px;
            right: -200px;
            z-index: 0;
        }

        .featuresNew {
            display: inline-flex;
            justify-content: start;
            text-align: left;
            flex-direction: column;
            width: 100%;
        }

        .featuresNew:nth-child(2) {
            border-left: 0 !important;
            border-right: 0 !important;
            border-top: rgba($color: white, $alpha: 0.5) solid 1px;
            border-bottom: rgba($color: white, $alpha: 0.5) solid 1px;
        }

        .featuresBoxes {
            background-color: rgba($color: #1F2027, $alpha: .95);
            // background-color: rgba(255, 255, 255, 0.11);
            // -moz-box-shadow: rgba(255, 255, 255, 0.15);
            // -webkit-box-shadow: 0px 0px 7px rgba(255, 255, 255, 0.15);
            // box-shadow: 0px 0px 7px rgba(255, 255, 255, 0.15);
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
        }

        .featuresBoxes {
            gap: 0 !important;
        }

        .blueCircle {
            border-radius: 50%;
            -webkit-filter: blur(75px);
            filter: blur(75px);
            height: 200px;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 200px;
            z-index: -1;
            background: rgba(23, 38, 244, 0.373);
        }

        .purpleCircle {
            border-radius: 50%;
            -webkit-filter: blur(75px);
            filter: blur(75px);
            height: 200px;
            position: absolute;
            bottom: 0px;
            right: 0px;
            width: 200px;
            z-index: -1;
            background: rgba(137, 94, 245, 0.277);
        }

        .videoContainer {
            width: 100%;
            margin-top: 30px;
            margin-bottom: 50px;
            height: 100%;
            overflow: visible !important;

            video {
                // width: 300px;
                width: 100% !important;
                height: auto !important;
            }

            .alertsImg {
                width: auto;
                height: 80px;
            }
        }

        .frugalLogo {
            width: 150px;
            margin-top: 0;
            margin-bottom: 25px;
        }

        .frugalCenter {
            text-align: center;
        }

        .featuresSection {
            display: flex !important;
            flex-direction: column !important;
            gap: 25px;
            align-items: flex-start;
            margin-top: 40px;

            .featureIconBox {
                border-radius: 10px;
                width: 40px;
                height: 40px;
            }

            .featuresText {
                font-weight: 500;
                font-size: 14px;
                width: unset !important;
            }
        }

        .featuresSectionNew {
            display: flex !important;
            flex-direction: column !important;
            gap: 25px;
            align-items: flex-start;
            margin-top: 40px;

            .featureIconBox {
                border-radius: 10px;
                width: 40px;
                height: 40px;
            }

            .featuresText {
                font-weight: 500;
                font-size: 14px;
                width: unset !important;
            }
        }

        video {
            min-width: unset !important;
        }
    }
}

#about {

    // max-height: 800px !important;
    .twoCol {
        width: 80%;
        // float: right;
        max-width: none !important;
        max-height: 700px !important;
        // padding: 50px 0 0 0 !important;
    }

    .featuresSection {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: 1fr;
        // flex-direction: row;
        align-items: flex-start;
        margin-top: 30px;
    }

    @media (max-width: 1260px) {
        .twoCol {
            float: unset !important;
            padding: 0 !important;
            width: 90%;
        }
    }
}

#frugal {
    position: relative;
    background-color: #0b0c15 !important;

    .planeImg {
        position: absolute;
        height: 70px;
        bottom: -2px;
        right: 30%;
        z-index: 0;
    }

    .featuresText {
        width: 120px;
    }
}

#freebie {
    background-image: url(../../Assets/freebieBg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;

    .twoCol {
        column-gap: 150px !important;
    }

    .logoBg {
        width: auto;
        height: 300px;
        position: absolute;
        bottom: 0;
        z-index: 1;
        right: 15%;
    }

    .featuresSection {
        display: flex !important;
        flex-direction: column;
        grid-template-columns: unset;
        gap: 25px !important;
        align-items: flex-start;
        margin-top: 40px !important;
        z-index: 1;
    }

    .freebieText {
        padding: 0 0 0 50px;
    }

    @media (max-width: 1260px) {
        background-image: url(../../Assets/freebieMobileBg.png);

        .freebieText {
            padding: 0 0 0 0;
        }
    }
}

.videoSection {
    position: relative !important;
    z-index: 99;

    .blueCircle {
        border-radius: 50%;
        -webkit-filter: blur(75px);
        filter: blur(75px);
        height: 300px;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 300px;
        z-index: -1;
        background: rgba(23, 38, 244, 0.373);
        overflow: visible !important;
    }

    .purpleCircle {
        border-radius: 50%;
        -webkit-filter: blur(75px);
        filter: blur(75px);
        height: 300px;
        position: absolute;
        top: 0px;
        right: 0px;
        width: 300px;
        bottom: unset !important;
        z-index: -1;
        background: rgba(137, 94, 245, 0.277);
        overflow: visible !important;
    }

    .travelImage {
        height: auto;
        width: 100%;
        overflow-y: hidden;
        margin-bottom: 30px;
    }
}

// .videoContainer {
//     scroll-snap-align: start;
//     scroll-snap-stop: always;
// }
// Elevate
#elevate {
    background-color: #13141c;
    padding: 80px 0;
    z-index: 1;
    position: relative;

    h2 {
        font-size: 38px;
        margin-bottom: 40px;
        text-align: center;
    }

    .landCards {
        display: flex;
        justify-content: center;
        // margin-top: 25px;
        gap: 15px;
        outline: rgba($color: white, $alpha: 0.06) 2px solid;
        width: fit-content;
        margin: 0 auto;
        padding: 15px;
        border-radius: 20px;
    }

    .cardContainer {
        max-width: 290px;
        background-color: rgba($color: white, $alpha: 0.06);
        border-radius: 20px;
        text-align: center;

        // padding: 20px;
        .cardHeader {
            background-color: rgba($color: white, $alpha: 0.04);
            padding: 18px;
            border-radius: 20px 20px 0 0;
        }

        h2 {
            font-size: 18px;
            margin: 0;
        }

        .cardInner {
            padding: 0 18px 18px 18px;
        }

        .cardImage {
            width: 100%;
            margin: 15px 0;
        }

        .joinButton {
            color: white;
            transition: 0.2s;
            // background: linear-gradient(93.99deg, #B224F5 0.76%, #31EDF9 104.49%);
            background-image: linear-gradient(to right, #b224f5 0%, #31edf9);
            background-size: 125% auto;
            padding: 15px 15px !important;
            width: 100%;
            border-radius: 10px;
            outline: none;
            border: none;
            font-size: 16px;
            font-weight: 700 !important;
        }

        .joinButton:hover {
            background-position: right center !important;
        }

        .learnButton {
            color: white;
            background: transparent;
            border: #8d8e93 solid 2px;
            padding: 15px 15px !important;
            width: 100%;
            border-radius: 10px;
            outline: none;
            font-size: 16px;
            font-weight: 700 !important;
        }

        .learnButton:hover {
            background: white;
            border: white solid 2px;
            color: black !important;
        }
    }

    @media (max-width: 768px) {
        height: unset;
        margin: 0 auto;

        h2 {
            width: 100%;
        }

        .elevateText {
            font-size: 30px !important;
            text-align: center;
        }

        .landCards {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 95% !important;
            // margin-top: 25px;
            gap: 10px;
            outline: none !important;
            border: rgba($color: white, $alpha: 0.06) 1px solid;
            width: fit-content;
            margin: 0 auto;
            padding: 5px !important;
            border-radius: 20px;
            -webkit-border-radius: 20px !important;
        }

        .landingText {
            padding: 0 30px;
        }

        .blueBlob {
            display: none;
        }

        .purpBlob {
            display: none;
        }

        .cardContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 0 auto;
            max-width: unset;
            justify-content: space-between;

            .cardHeader {
                padding: 15px 5px;
            }

            .cardInner {
                padding: 0 10px 10px 10px;
            }

            .joinButton {
                color: white;
                transition: 0.2s;
                // background: linear-gradient(93.99deg, #B224F5 0.76%, #31EDF9 104.49%);
                background-image: linear-gradient(to right, #b224f5 0%, #31edf9);
                background-size: 125% auto;
                padding: 15px 0 !important;
                width: 100%;
                border-radius: 10px;
                outline: none;
                border: none;
                font-size: 11px;
                font-weight: 600 !important;
            }

            .learnButton {
                color: white;
                background: transparent;
                border: #8d8e93 solid 1px;
                padding: 15px 0 !important;
                width: 100%;
                border-radius: 10px;
                outline: none;
                font-size: 11px;
                font-weight: 600 !important;
            }

            .headerText {
                font-weight: 700;
                font-size: 11px;
                text-align: center;
            }
        }
    }
}

.emailFormInput {
    border-radius: 10px;
    border: 1px solid white;
    width: 100%;
    padding: 10px;
    color: white;
    background-color: rgba($color: white, $alpha: 0.00);
    align-items: center;
}

.doubleForm {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
}

.emailForm {
    display: flex;
    flex-direction: column;
    gap: 13px;
    align-items: center;
    width: 100%;
}

.continueButton {
    color: white;
    transition: 0.2s;
    background: linear-gradient(93.99deg, #b224f5 0.76%, #31edf9 104.49%);
    background-size: 125% auto;
    padding: 13px 10px !important;
    width: 30%;
    border-radius: 10px;
    outline: none;
    border: none;
    font-size: 16px;
    font-weight: 700 !important;
}

.emailInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    width: 100%;
}

@media screen {
    .emailForm {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 3rem;
        align-items: center;
    }

    .emailFormInput {
        width: 100%;
    }

    .doubleForm {
        width: 100%;
    }

    .continueButton {
        width: 80%;
    }

    h1 {
        font-size: 30px;
        font-weight: 700;
        margin: 0;
        margin-bottom: 20px;
    }

    .emailInputOuter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 3rem;
        align-items: center;
    }

    .emailFormInput {
        width: 80%;
    }
}